import { useFetcher } from "@remix-run/react";
import { useEffect } from "react";

import BannerPromotion from "~/components/organisms/BannerPromotion";
import { ISection } from "~/entities/section";
import { THomeLoaderData } from "~/routes/_index";
import { LIMIT, PAGE } from "~/utilities/constants/common";
import { EApiParam } from "~/utilities/enums/ApiParam";

export interface SectionBannerPromotionProps {
  data: ISection;
}

const SectionBannerPromotion: React.FC<SectionBannerPromotionProps> = ({
  data,
}) => {
  const fetcher = useFetcher<THomeLoaderData>();
  useEffect(() => {
    const formData = new FormData();
    formData.append(EApiParam.PROMOTION_PAGE, `${PAGE}`);
    formData.append(EApiParam.PROMOTION_LIMIT, `${LIMIT}`);
    formData.append(EApiParam.SECTION_ID, data?.id || "");
    fetcher.submit(formData, {
      method: "GET",
      preventScrollReset: true,
    });
  }, []);
  return (
    <section
      id="banner-promotion"
      style={{ backgroundColor: data?.background }}
    >
      <div className="py-9">
        <BannerPromotion
          heading={data?.name}
          background={data?.background}
          promotions={fetcher?.data?.promotionBanners || []}
        />
      </div>
    </section>
  );
};

export default SectionBannerPromotion;
