import isEmpty from "lodash/isEmpty";
import { SwiperOptions } from "swiper/types";

import { Swiper, SwiperSlide } from "~/components/atoms/Swiper";
import Typo from "~/components/atoms/Typo";
import CardFeature from "~/components/molecules/CardFeature";
import LinkViewAll from "~/components/molecules/LinkViewAll";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface SlideCardsMobileProps {
  projects: any[];
  name: string;
  link: string;
}

export default function SlideCardsMobile({
  projects,
  link,
  name,
}: SlideCardsMobileProps) {
  const swiperOptions: SwiperOptions = {
    slidesPerView: 1,
    pagination: {
      clickable: true,
      el: ".swiper-pagination-slideCardsMobile",
    },
  };

  return (
    <div className="swiper__slideCardsMobile">
      <div className="container  sm:px-6">
        <Typo
          tag={ETypoTag.DIV}
          variant={ETypoVariant.HEADER_32}
          className="break-words font-bold md:text-header-20"
        >
          <div
            dangerouslySetInnerHTML={{ __html: name }}
            className="ql-editor"
          />
        </Typo>
      </div>
      <Swiper className="flex flex-row items-stretch" {...swiperOptions}>
        {!isEmpty(projects) &&
          projects.map((item) => {
            return (
              <SwiperSlide
                className="group h-auto min-h-max w-full max-w-[688px] p-6"
                key={item.id}
              >
                <CardFeature
                  project={item}
                  className="block h-full w-full max-w-[688px]"
                  thumbnailClass="max-h-[176px]"
                />
              </SwiperSlide>
            );
          })}
      </Swiper>
      {/* PAGINATION */}

      <div className="container flex flex-row items-center justify-center sm:px-6">
        {/* <div
          className={cn(
            "relative z-[50]  flex w-full  flex-row  gap-x-3  *:size-2 *:rounded-full *:bg-gableGreen25 [&>.swiper-pagination-bullet-active]:bg-gableGreen75",
            `swiper-pagination-slideCardsMobile`
          )}
        ></div> */}
        <LinkViewAll slug={link} />
      </div>
    </div>
  );
}
